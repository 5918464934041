<template>
  <a-layout class="layout">
    <a-layout-header :style="{ 'height': '75px', 'lineHeight': '75px' }">
      <div class="logo">
        <img src="@/assets/logo-white.svg" alt="">
      </div>
      <!-- <a-menu
        v-model:selectedKeys="selectedKeys"
        theme="dark"
        mode="horizontal"
        :style="{ lineHeight: '64px' }"
      > -->
      <!-- <a-menu-item key="1">nav 1</a-menu-item> -->
      <!-- <a-menu-item key="2">nav 2</a-menu-item> -->
      <!-- <a-menu-item key="3">nav 3</a-menu-item> -->
      <!-- </a-menu> -->
    </a-layout-header>
    <a-layout-content style="padding: 0 50px;">
      <div :style="{ padding: '24px', minHeight: '280px' }">
        <router-view></router-view>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import { defineComponent, ref } from 'vue';
export default defineComponent({
  setup() {
    return {
      selectedKeys: ref(['2'])
    };
  },
});
</script>
<style lang="scss">
#app,
html,
body {
  background: #001529;
  height: 100%;
  color: #fff;
  text-align: center;
}

h1,
h2,
h3,
h4 {
  color: #fff !important;
}

.layout {
  background: #001529 !important;
  height: 100%;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  // background: #fff;
  // background: #001529;
}

.logo {
  // float: left;
  // float: center;
  text-align: center;

  // margin-right: 20px;
  img {
    height: 40px;
  }
}

// [data-theme='dark'] .site-layout-content {
// background: #141414;
// }

.swal2-title {
  color: #001529 !important;
}

.page-title {
  font-size: 1.3em;
  margin-bottom: 30px;
}

.ant-btn-primary {
  border-color: #F7941D !important;
  background: #F7941D !important;
  margin-bottom: 20px;
  border-radius: 50px;
  height: 60px;
  font-size: 20px;
  font-weight: 600;
}
</style>
