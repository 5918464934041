<template>
  <div class="home">
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'HomeView',
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  components: {
  },
  created() {
    if (!this.user) {
      this.$router.push('/login');
    } else {
      this.$router.push('/dashboard');
    }
  }
}
</script>
