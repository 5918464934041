import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

window.API_BASE = "http://sfj-admin.test";
if (window.location.host == 'go.thesunshineco.co.uk') {
    window.API_BASE = "https://sfj.enhanceable.io";
}

axios.defaults.headers.common['Password'] = `ipad-signup-password`;

createApp(App).use(Antd).use(store).use(router).mount('#app')
