import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: {
    user: null,
    leadSource: null
  },
  getters: {
    user: (state) => {
      return state.user
    },
    leadSource: (state) => {
      return state.leadSource
    }
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_LEAD_SOURCE(state, leadSource) {
      state.leadSource = leadSource;
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setLeadSource({ commit }, leadSource) {
      commit('SET_LEAD_SOURCE', leadSource);
    }
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
